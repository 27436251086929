// OBJECT CONTAINER
// ================

.#{$object}container {
  padding-left: $space-size;
  padding-right: $space-size;
  margin-left: auto;
  margin-right: auto;
  max-width: $container-default;
  height: 100%;

  @include breakpoint(tablet-up) {
    padding-left: $large-space-size;
    padding-right: $large-space-size;
  }

  &--fluid {
    max-width: initial;
    width: $container-fluid;
  }

  &--bleed {
    padding: 0;
  }

  &--small {
    max-width: $container-small;
  }

  &--tiny {
    max-width: $container-tiny;
  }

  &--micro {
    max-width: $container-micro;
  }
}
