* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-size: 100%;
  color: $color-black;
  font-family: $primary-font;
  font-kerning: normal;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: $color-blue;
  transition: $transition;

  &:hover {
    color: $color-black;
  }
}

video {
  width: 100% !important;
  height: auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: $color-text-grey;
  letter-spacing: .03em;
}

:-ms-input-placeholder {
  color: $color-text-grey;
  letter-spacing: .03em;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: $color-white;

  tr {
    border: 1px solid $color-light-grey;
    &:nth-child(even) {
      background: $color-grey;
    }
  }

  th {
    background: $color-grey;
    text-align: initial;
  }

  th,
  td {

    border: 1px solid $color-light-grey;
    vertical-align: top;

    @include breakpoint(phone-only) {


      &:before {
        content: attr(data-th) "" !important;
      }
    }

    @include breakpoint(tablet-up) {


    }

  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 40px;

  @include breakpoint(tablet-up) {
    font-size: 56px;
  }
}
h2 {
  font-size: 30px;

  @include breakpoint(tablet-up) {
    font-size: 40px;
  }
}
h3 {
  font-size: 28px;

  @include breakpoint(tablet-up) {
    font-size: 32px;
  }
}
h4 {
  font-size: 22px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
