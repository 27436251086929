// UTILITIES TEXT
// ================


.#{$utility} {

  &txt-base {
    font-size: 14px !important;
    line-height: 1.4 !important;
  }

  &txt-normal {
    font-weight: normal !important;
  }

  &txt-underline {
    text-decoration: underline !important;
  }

  &txt-no-decoration {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &txt-strikethrough {
    text-decoration: line-through !important;
  }

  &txt-strong,
  &txt-bold {
    font-weight: bold !important;
  }

  &txt-semibold {
    font-weight: 500 !important;
  }

  &txt-italic {
    font-style: italic !important;
  }

  &txt-capitalize {
    text-transform: capitalize !important;
  }

  &txt-upcase {
    text-transform: uppercase !important;
  }

  &txt-downcase {
    text-transform: lowercase !important;
  }

  &txt-double-quoted {
    &::before,
    &::after {
      content: '\"';
    }
  }

  &txt-nowrap {
    white-space: nowrap;
  }

  &txt-wrap {
    white-space: normal !important;
  }

  &txt-preline {
    white-space: pre-line;
  }

  &txt-break-word {
    word-break: break-word !important;
  }

  &txt-break-normal {
    word-break: normal !important;
  }

  &txt-break-all {
    word-break: break-all;
  }

  &txt-normal-white-space {
    white-space: normal;
  }

  &txt-text-transform-none {
    text-transform: none !important;
  }

  &txt-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: nowrap;

    // white-space: nowrap cause overflow inside table
    // need hack to set width
    &-inside-table {
      width: 0;
      min-width: 100%;
    }

    &-in-table {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // To add ellipsis if the text is inside a table cell
  // Reference: http://jsfiddle.net/maruxa1j/
  &txt-ellipsis-in-table-cell {
    position: relative;

    &:before {
      content: "&nbsp;";
      visibility: hidden;
    }

    span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}
