// NAMESPACE
// ================
$object:     "o-" !default;
$component:     "c-" !default;
$utility:        "u-" !default;
$organism:        "g-" !default;

// CONTAINER
// ================
$container-fluid: 100%;
$container-default: 1200px;
$container-small: 1000px;
$container-tiny: 800px;
$container-micro: 590px;


$header-height:         65px;
$header-height-reducer: 15px;
$border-radius:         2px;
$border-radius-large:   12px;

// COLOR
// ================
$color-white:        #ffffff;
$color-black:        #000000;
$color-light-grey:   #e1e1e1;
$color-grey:         #FAF7F7;
$color-dark-grey:    #ECECEC;
$color-brand:        #FF0000;
$color-blue:         #1B53EE;
$color-dark-blue:    #002077;
$color-border:       #BCBCBC;
$color-text-grey:    #828282;

$transition:         all .2s linear;
$shadow:             0 4px 9px 0 rgba(153, 153, 153, .13), 0 0 4px 0 rgba(153, 153, 153, .04);
$shadow-sharp:       0 1px 2px 0 rgba(0, 0, 0, .08), 0 0 1px 0 #bbb;
$shadow-hover:       0 0 10px rgba(0, 0, 0, .2);

// WHITESPACE
// ================
$space-size:   20px;
$large-space-size:   35px;

// TYPOGRAPHY
// ================
$font-size:          16px;
$line-height:        24px;
$primary-font:       "PT Sans", sans-serif;
$secondary-font:     "Oswald", sans-serif;

$available-font-size: (
  tiny:     10px,
  small:    12px,
  desc:     14px,
  base:     $font-size,
  medium:   20px,
  large:    24px,
  xlarge:   28px,
  xxlarge:  32px,
  hero:     40px
) !default;

$available-line-height-ratio: (
  tiny:     1.43,
  small:    1.43,
  base:     1.5,
  fair:     1.5,
  medium:   1.5,
  large:    1.4,
  xlarge:   1.4,
  xxlarge:  1.5,
  hero:     1.5
) !default;

$utilities-colors: (
  "white":        $color-white,
  "black":        $color-black,
  "light-grey":   $color-light-grey,
  "grey":         $color-grey,
  "dark-grey":    $color-dark-grey,
  "brand":        $color-brand,
  "blue":         $color-blue,
  "dark-blue":    $color-dark-blue,
  "border":       $color-border,
  "text-grey":    $color-text-grey,
) !default;

$external-colors: (
  "facebook":   #3b5998,
  "twitter":    #55acee,
  "gplus":      #dc4e41,
  "pinterest":  #cb2027,
  "gmail":      #c63737,
  "yahoo":      #a207d6,
  "whatsapp":   #25d366,
  "line":       #01ba00
) !default;
