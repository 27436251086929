@import "scss/style";

.#{$component}wrapper {
  position: relative;
  background: $color-white;
  min-height: calc(100vh - 70px);

  &--grey {
    background: $color-grey;
  }
}
