@import "../../../scss/style";

.#{$component}post {
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: .03em;
  }

  p {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  ol li, ul li {
    font-size: 20px;
    line-height: 1.4;
  }

  ol {
    ol {
      li {
        list-style-type: lower-alpha;
      }
    }
  }

}
