// OBJECT GRID
// ================

.#{$object}grid {
  @extend .row;
  margin-left: -($space-size);
  margin-right: -($space-size);
  height: 100%;

  @include breakpoint(tablet-up) {
    margin-left: -($large-space-size);
    margin-right: -($large-space-size);
  }

  &--center {
    justify-content: center;
  }

  &--bleed {
    margin-left: 0;
    margin-right: 0;

    .#{$object}grid__item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__item {
    padding-left: $space-size;
    padding-right: $space-size;

    @include breakpoint(tablet-up) {
      padding-left: $large-space-size;
      padding-right: $large-space-size;
    }
  }
}
