// ====================
// whitespaces utilities
// ====================

// use these utility classes to add space between regions;


.#{$utility} {

  $spaceamounts: (5, 8, 10, 15, 16, 20, 24, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
  $sides: (top, bottom, left, right);

  @each $space in $spaceamounts {
    @each $side in $sides {
      &m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space - 5}px;

        @include breakpoint(tablet-up) {
          margin-#{$side}: #{$space}px;
        }
      }

      &p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space - 5}px;

        @include breakpoint(tablet-up) {
          padding-#{$side}: #{$space}px;
        }
      }
    }
  }

  &mrgn-auto {
    margin: auto;
  }
}
