
// CLEARFIX
// ================
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}

// SCROLLBAR
// ================
@mixin scrollbar() {
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $color-light-purple;
    border-right: 0;
    border-left: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-dark-purple;
    border: 3px solid $color-light-purple;
  }
}

// CENTER ABSOLUTE
// ================
@mixin center-absolute($left: 50%, $top: 50%) {
  left: $left;
  top: $top;
  transform: translate(-$left, -$top);
}

// lAYER
// ================
@mixin layer() {
  width: 100%;
  position: absolute;
  display: block;
  content: "";
}

// TEXT OVERFLOW
// ================
@mixin text-overflow($overflow-param: ellipsis) {
  white-space: nowrap;
  text-overflow: $overflow-param;
  overflow: hidden;
}

// BREAKPOINT
// ================
@mixin breakpoint($range) {
  $phone: 720px;
  $tablet: 960px;
  $desktop: 1024px;

  // phone only
  @if $range == phone-only {
    @media (max-width: $phone - 1) { @content; }
  }

  // phone up
  @else if $range == phone-up {
    @media (min-width: $phone) { @content; }
  }

  // tablet up
  @else if $range == tablet-up {
    @media (min-width: $tablet) { @content; }
  }

  // desktop up
  @else if $range == desktop-up {
    @media (min-width: $desktop) { @content; }
  }

  // WARNING: card and dot navigation inside Hero only
  @else if $range == hero-medium {
    @media (min-width: 1200px) { @content; }
  }
  @else if $range == hero-large {
    @media (min-width: 1366px) { @content; }
  }
}

// Ellipsis
@mixin ellipsis($lines: 2) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
}
