// UTILITIES MISC
// ================

.u-overflow-hidden {
  overflow-y: hidden;
}

.u-overflow-touch {
  -webkit-overflow-scrolling: touch;
}

.u-display-block {
  display: block !important;
}

.u-display-none {
  display: none !important;
}

.u-display-inherit {
  display: inherit !important;
}

.u-blur {
  filter: blur(2px);
}

.u-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.u-desktop-only {
  display: none;
  @include breakpoint(tablet-up) {
    display: block;
  }
}

.u-mobile-only {
  display: block;
  @include breakpoint(tablet-up) {
    display: none;
  }
}

.u-relative {
  position: relative;
}

.u-full-height {
  height: 100%;
}

// Google Recaptcha
#rc-imageselect,
.g-recaptcha {
  @include breakpoint(phone-only) {
    transform: scale(.8);
    transform-origin: 0 0;
  }
}

// drawer active
body.is-active {
  overflow: hidden;
}

//Whatsapp icon bottom right
.whatsapp{
	position:fixed;
	width:40px;
	height:40px;
	bottom:20px;
	right:10px;
	background-color:#25d366;
	color: $color-white;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px $shadow-sharp;
  z-index:100;
  transition: $transition;
  display: block;

  @include breakpoint(tablet-up) {
    width:60px;
    height:60px;
    bottom:40px;
    right:30px;
  }
}

.whatsapp-icon {
  position: absolute;
  display: block;
  line-height: 0;
  font-size: 22px;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);

  @include breakpoint(tablet-up) {
    left: 51%;
    font-size: 30px;
  }
}

// Responsive iframe
.iframe-container {
  overflow: hidden;
  position: relative;
  width:100%;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
