// UTILITIES ALIGNMENT
// ================

.#{$utility} {

  &align-center {
    text-align: center !important;
  }
  &align-left {
    text-align: left !important;
  }
  &align-right {
    text-align: right !important;
  }
  &align-justify {
    text-align: justify !important;
  }


  &valign-top {
    vertical-align: top !important;
  }
  &valign-middle {
    vertical-align: middle !important;
  }
  &valign-bottom {
    vertical-align: bottom !important;
  }

}
